import { combineReducers } from "redux"
import customizer from "./customizer"
import auth from "./auth"
import navbar from "./navbar/Index"
import project from "./projects"
import cluster from "./clusters"
import farmer from "./farmers"
import input from "./inputs"
import inventory from "./inventory"
import qualityReport from "./quality_report"
import disbursement from "./disbursement"
import user from "./users"

const rootReducer = combineReducers({
  customizer,
  auth,
  navbar,
  project,
  cluster,
  farmer,
  user,
  input,
  inventory,
  qualityReport,
  disbursement,
})

export default rootReducer
