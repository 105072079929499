const initialState = {
  inputs: [],
}

const inputList = (state = initialState, action) => {
  switch (action.type) {
    case "GET_INPUTS":
      return { ...state, inputs: action.inputs }

    default:
      return { ...state }
  }
}

export default inputList
