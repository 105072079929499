const initialState = {
  users: [],
  projectUsers: [],
}

const userList = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS":
      return { ...state, users: action.users }
    case "GET_PROJECT_USERS":
      return { ...state, projectUsers: action.projectUsers }
    case "DELETE_PROJECT_USER":
      return { ...state, projectUsers: action.projectUsers }
    case "DELETE_MANY_PROJECT_USERS":
      return { ...state, projectUsers: action.projectUsers }
    default:
      return { ...state }
  }
}

export default userList
