const initialState = {
  farmers: [],
}

const farmerList = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FARMERS":
      return { ...state, farmers: action.farmers }

    default:
      return { ...state }
  }
}

export default farmerList
