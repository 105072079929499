const initialState = {
  myDisbursements: [],
  incomingRequests: [],
  incomingInputs: [],
  myRequests: [],
}

const disbursement = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DISBURSEMENTS":
      return { ...state, myDisbursements: action.disbursements }

    case "GET_INCOMING_REQUESTS":
      return { ...state, incomingRequests: action.incomingRequests }

    case "GET_INCOMING_INPUTS":
      return { ...state, incomingInputs: action.incomingInputs }

    case "GET_MY_REQUESTS":
      return { ...state, myRequests: action.myRequests }

    default:
      return { ...state }
  }
}

export default disbursement
