const initialState = {
  qualityReports: [],
  myReports: [],
}

const qualityReport = (state = initialState, action) => {
  switch (action.type) {
    case "GET_QUALITY_REPORTS":
      return { ...state, qualityReports: action.qualityReports }

    case "GET_MY_REPORTS":
      return { ...state, myReports: action.myReports }

    default:
      return { ...state }
  }
}

export default qualityReport
