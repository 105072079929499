import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import * as Icon from "react-feather"
import { history } from "../../../history"
import http from "../../../services/Apicalls"

const UserDropdown = () => {
  if(localStorage.getItem("persist:root")) {
    var data = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).auth).login;
    // console.log(data.userRole, data.values.loggedInUser.data.id);
  }
  
  return (
    <DropdownMenu right>
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        onClick={(e) => {
          e.preventDefault()
          var data = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).auth).login;
          history.push(`/profile/update`, data.values.loggedInUser.data)
        }}>
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Profile</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        onClick={(e) => {
          e.preventDefault()
          var data = JSON.parse(JSON.parse(localStorage.getItem("persist:root")).auth).login;
          history.push(`/picture/update`, data.values.loggedInUser.data)
        }}
        >
        <Icon.Image size={14} className="mr-50" />
        <span className="align-middle">Update Image</span>
      </DropdownItem>
      <DropdownItem
        tag="a"
        onClick={(e) => {
          e.preventDefault()
          http.get("/auth/logout").then((response) => {
            if (response.data.status.type === "success") {
              localStorage.clear()
              // history.push("/login")
              window.location.reload()
            } else {
              alert("unable to log out")
            }
          })
        }}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  
  )
}

class NavbarUser extends React.PureComponent {
  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status">Welcome to your dashboard</span>
            </div>
            <span data-tour="user">
              <Icon.Power size={24} />
            </span>
          </DropdownToggle>
          <UserDropdown />
        </UncontrolledDropdown>
      </ul>
    )
  }
}
export default NavbarUser
