import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Button,
} from "reactstrap"
import { Menu, X } from "react-feather"
import "../../../assets/scss/pages/landing.scss"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  return (
    <div className="px-0 mx-0 landing-page">
      <Navbar className="header" light expand="md">
        <NavbarBrand href="/#">
          <img
            src={require("../../../assets/img/pages/landing/fl-logo.webp")}
            alt="foodlocker-logo"
          />
        </NavbarBrand>
        <NavbarToggler onClick={toggle}>
          {!isOpen ? <Menu /> : <X />}
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar />
          <Link className="mr-1 header-link" to="/signup">
            Sign Up
          </Link>
          <div>
            <Button
              className="header-login-btn"
              color="success"
              as="a"
              href="/demand-bid/create"
            >
              Order Commodity
            </Button>
          </div>
          <div>
            <Button
              className="header-login-btn"
              color="success"
              as="a"
              href="/integrated-cattle-grains"
            >
              Integrated Cattle and Grains Production
            </Button>
          </div>
          <div>
            <Button
              className="header-login-btn"
              color="success"
              as="a"
              href="/login"
            >
              GAIN ACCESS
            </Button>
          </div>
        </Collapse>
      </Navbar>
    </div>
  )
}

export default Header
