import React from "react"
import * as Icon from "react-feather"

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/dashboard",
    permissions: [
      "is_super_admin",
      "is_project_manager",
      "is_cluster_manager",
      "is_farmer",
      "view_dashboard",
    ],
    // badge: "success",
    // badgeText: "2",
  },
  {
    id: "projects",
    title: "Projects",
    type: "collapse",
    icon: <Icon.Layers size={20} />,
    permissions: [
      "is_super_admin",
      "is_project_manager",
      "is_cluster_manager",
      "browse_project",
      // "is_farmer",
    ],
    children: [
      {
        id: "project_type_list",
        title: "Project Types",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin"],
        navLink: "/project/type/list",
      },
      {
        id: "project_manager_list",
        title: "Project Managers",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          "is_project_manager",
          "is_cluster_manager",
          "browse_manager",
          // "is_farmer",
        ],
        navLink: "/project/manager/list",
      },
      // {
      //   id: "project_type_config_list",
      //   title: "Project Type Config",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["is_super_admin"],
      //   navLink: "/project-type/config/list",
      // },
      // {
      //   id: "project_type_config",
      //   title: "Set Project Config",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["is_super_admin"],
      //   navLink: "/project-type/config/create",
      // },
      {
        id: "projectlist",
        title: "All Projects",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          "is_project_manager",
          "is_cluster_manager",
          "browse_project",
          // "is_farmer",
        ],
        navLink: "/projects/list",
      },
      {
        id: "projectgallerysetup",
        title: "Image Report Setup",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          "is_project_manager",
          // "browse_project",
          // "is_farmer",
        ],
        navLink: "/project/gallery/config/list",
      },
      {
        id: "project_variable_cost",
        title: "One Time Costs",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin", "is_project_manager"],
        navLink: "/project/variable/cost/list",
      },
      // {
      //   id: "projectcreate",
      //   title: "Create Project",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/project/create",
      // },
      // {
      //   id: "create_project_manager",
      //   title: "Create Manager",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["admin", "editor"],
      //   navLink: "/project/manager/create",
      // },
    ],
  },
  {
    id: "clusters",
    title: "Clusters",
    type: "collapse",
    icon: <Icon.Grid size={20} />,
    permissions: [
      "is_super_admin",
      "is_project_manager",
      "is_cluster_manager",
      "browse_cluster",
      // "is_farmer",
    ],
    children: [
      {
        id: "cluster_manager_list",
        title: "Cluster Managers",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin", "is_project_manager"],
        navLink: "/cluster/manager/list",
      },
      {
        id: "clusterlist",
        title: "All Clusters",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          "is_project_manager",
          "is_cluster_manager",
          "browse_cluster",
          // "is_farmer",
        ],
        navLink: "/cluster/list",
      },
    ],
  },
  {
    id: "farmers",
    title: "Farmers",
    type: "item",
    icon: <Icon.Cpu size={20} />,
    permissions: [
      "is_super_admin",
      "is_project_manager",
      "is_cluster_manager",
      // "is_farmer",
      "browse_farmers",
    ],
    navLink: "/farmer/list",
    // children: [
    //   {
    //     id: "farmerlist",
    //     title: "All Farmers",
    //     type: "item",
    //     icon: <Icon.Circle size={12} />,
    //     permissions: [
    //       "is_super_admin",
    //       "is_project_manager",
    //       "is_cluster_manager",
    //       "is_farmer",
    //       "browse_farmers",
    //     ],
    //     navLink: "/farmer/list",
    //   },
    // ],
  },
  {
    id: "standard",
    title: "Reports",
    type: "collapse",
    icon: <Icon.FileText size={20} />,
    permissions: [
      "is_super_admin",
      "is_project_manager",
      "is_cluster_manager",
      // "is_farmer",
      "browse_project",
    ],
    children: [
      {
        id: "myreport",
        title: "Reports Table",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          "is_project_manager",
          "is_cluster_manager",
          // "is_farmer",
          "browse_project",
        ],
        navLink: "/report/list",
      },
      {
        id: "qualityreport",
        title: "Reports Visualization",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          "is_project_manager",
          "browse_project",
          // "is_cluster_manager",
          // "is_farmer",
        ],
        navLink: "/report/expectation",
      },
      {
        id: "imagereportlist",
        title: "Image Reports",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          // "is_project_manager",
          // "browse_project",
          // "is_cluster_manager",
          // "is_farmer",
        ],
        navLink: "/report/image/expectation",
      },
      {
        id: "reportcreate",
        title: "Submit Daily Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          // "is_super_admin",
          // "is_project_manager",
          "is_cluster_manager",
          // "is_farmer",
        ],
        navLink: "/report/create",
      },
      {
        id: "reportimagecreate",
        title: "Submit Photo Report",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          "is_project_manager",
          "is_cluster_manager",
          // "is_farmer",
        ],
        navLink: "/report/photo/create",
      },
    ],
  },
  {
    id: "users",
    title: "Users",
    type: "collapse",
    icon: <Icon.Users size={20} />,
    permissions: ["is_super_admin"],
    navLink: "/user/list",
    children: [
      {
        id: "userlist",
        title: "All Users",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin"],
        navLink: "/user/list",
      },
      {
        id: "user-project-list",
        title: "Project Users",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin"],
        navLink: "/user/enterprise/list",
      },
      {
        id: "user-roles",
        title: "Users Roles",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin"],
        navLink: "/user/roles/list",
      },
      // {
      //   id: "userpendingfarmerlist",
      //   title: "Pending SMS Farmers",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: ["is_super_admin", "is_project_manager"],
      //   navLink: "/user/pending/farmer",
      // },
    ],
  },
  {
    id: "inputs",
    title: "Inputs",
    type: "collapse",
    icon: <Icon.FilePlus size={20} />,
    permissions: [
      "is_super_admin",
      "is_project_manager",
      "is_cluster_manager",
      "is_farmer",
    ],
    children: [
      // {
      //   id: "inputlist",
      //   title: "Input Table",
      //   type: "item",
      //   icon: <Icon.Circle size={12} />,
      //   permissions: [
      //     "is_super_admin",
      //     // "is_project_manager",
      //     // "is_cluster_manager",
      //     // "is_farmer",
      //   ],
      //   navLink: "/input/list",
      // },
      {
        id: "inventorylist",
        title: "Inventory",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          // "is_project_manager",
          // "is_cluster_manager",
          // "is_farmer",
        ],
        navLink: "/inventory/list",
      },
      {
        id: "inputbalance",
        title: "Input Balance",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          // "is_super_admin",
          "is_project_manager",
          "is_cluster_manager",
          "is_farmer",
        ],
        navLink: "/input-balance/list",
      },
    ],
  },
  {
    id: "disburse",
    title: "Disbursement",
    type: "collapse",
    icon: <Icon.Share2 size={20} />,
    permissions: [
      "is_super_admin",
      "is_project_manager",
      "is_cluster_manager",
      "is_farmer",
    ],
    children: [
      {
        id: "reqInput",
        title: "Request for Input",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_project_manager", "is_cluster_manager", "is_farmer"],
        navLink: "/request/create",
      },
      {
        id: "myreqInput",
        title: "My Input Request ",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_project_manager", "is_cluster_manager", "is_farmer"],
        navLink: "/my/request",
      },
      {
        id: "disburseinput",
        title: "Disburse Input",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          "is_project_manager",
          "is_cluster_manager",
        ],
        navLink: "/disburse/input",
      },
      {
        id: "incominginput",
        title: "Incoming Input",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_project_manager", "is_cluster_manager", "is_farmer"],
        navLink: "/incoming/input/list",
      },
      {
        id: "disburselist",
        title: "My Disbursement",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          "is_project_manager",
          "is_cluster_manager",
        ],
        navLink: "/disbursement/list",
      },
      {
        id: "disburserequest",
        title: "Incoming Request",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: [
          "is_super_admin",
          "is_project_manager",
          "is_cluster_manager",
        ],
        navLink: "/incoming/request/list",
      },
    ],
  },
  {
    id: "sms",
    title: "Send SMS",
    type: "collapse",
    icon: <Icon.MessageSquare size={20} />,
    permissions: ["is_super_admin", "is_project_manager"],
    children: [
      {
        id: "nagsms",
        title: "Nag SMS",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin", "is_project_manager"],
        navLink: "/sms/nag",
      },
      {
        id: "projectsms",
        title: "By Project",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin", "is_project_manager"],
        navLink: "/sms/project",
      },
      {
        id: "clustersms",
        title: "By Cluster",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin", "is_project_manager"],
        navLink: "/sms/cluster",
      },
      {
        id: "farmersms",
        title: "By Farmer",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin", "is_project_manager"],
        navLink: "/sms/farmer",
      },
      {
        id: "smslist",
        title: "All SMS Sent",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin", "is_project_manager"],
        navLink: "/sms/list",
      },
    ],
  },
  {
    id: "demand-bid",
    title: "Demand Bids",
    type: "item",
    icon: <Icon.Droplet size={20} />,
    navLink: "/demand-bid/list",
    badge: "success",
  },
  {
    type: "groupHeader",
    groupTitle: "REVENUE PROFILE",
    permissions: ["is_super_admin", "browse_project"],
  },
  {
    id: "revenue_cost",
    title: "Revenue Profile",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    navLink: "/revenue",
    permissions: ["is_super_admin", "browse_project"],
    // badge: "success",
    // badgeText: "2",
  },
  {
    type: "groupHeader",
    groupTitle: "SETTINGS",
    permissions: ["is_super_admin", "is_project_manager"],
  },
  {
    id: "settings",
    title: "Settings",
    type: "collapse",
    icon: <Icon.Settings size={20} />,
    permissions: ["is_super_admin", "is_project_manager"],
    children: [
      {
        id: "project_settings",
        title: "Project Settings",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin", "is_project_manager"],
        navLink: "/settings/project",
      },
      {
        id: "cluster_settings",
        title: "Cluster Settings",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin", "is_project_manager"],
        navLink: "/settings/cluster",
      },
      {
        id: "farmer_settings",
        title: "Farmer Settings",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["is_super_admin", "is_project_manager"],
        navLink: "/settings/farmer",
      },
    ],
  },
  {
    type: "groupHeader",
    groupTitle: "PAYMENT & ACCOUNTS",
    permissions: [
      "is_super_admin",
      "is_project_manager",
      "is_cluster_manager",
      "is_farmer",
    ],
  },
  {
    id: "make_payments",
    title: "Dispatch Payments",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: ["is_super_admin"],
    navLink: "/account/pay",
  },
  {
    id: "view_acc",
    title: "Add Account",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: ["is_project_manager", "is_cluster_manager", "is_farmer"],
    navLink: "/account/view",
  },

  {
    type: "groupHeader",
    groupTitle: "LOG RECORDS",
    permissions: ["is_super_admin"],
  },
  {
    id: "logs",
    title: "Logs",
    type: "item",
    icon: <Icon.Watch size={20} />,
    navLink: "/logs",
    permissions: ["is_super_admin"],
    // badge: "success",
    // badgeText: "2",
  },
  // {
  //   id: "logs",
  //   title: "Logs",
  //   type: "collapse",
  //   icon: <Icon.Watch size={20} />,
  //   children: [
  //     {
  //       id: "logsdetails",
  //       title: "All Logs",
  //       type: "item",
  //       icon: <Icon.AlertCircle size={20} />,
  //       navLink: "/logs",
  //       permissions: ["is_super_admin"],
  //       // badge: "success",
  //       // badgeText: "2",
  //     },
  //   ],
  // },
  // {
  //   id: "email",
  //   title: "Email",
  //   type: "item",
  //   icon: <Icon.Mail size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/email/:filter",
  //   filterBase: "/email/inbox"
  // },
  // {
  //   id: "chat",
  //   title: "Chat",
  //   type: "item",
  //   icon: <Icon.MessageSquare size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/chat"
  // },
  // {
  //   id: "todo",
  //   title: "Todo",
  //   type: "item",
  //   icon: <Icon.CheckSquare size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/todo/:filter",
  //   filterBase: "/todo/all"
  // },
  // {
  //   id: "calendar",
  //   title: "Calendar",
  //   type: "item",
  //   icon: <Icon.Calendar size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/calendar"
  // },
]

export default navigationConfig
