const initialState = {
  inventories: [],
  inputBalance: [],
}

const inventoryList = (state = initialState, action) => {
  switch (action.type) {
    case "DELETE_INVENTORY":
      return { ...state, inventories: action.inventories }

    case "GET_INVENTORIES":
      return { ...state, inventories: action.inventories }

    case "GET_INPUT_BALANCE":
      return { ...state, inputBalance: action.inputBalance }

    default:
      return { ...state }
  }
}

export default inventoryList
